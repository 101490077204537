<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container class="fill-height login" fluid>
        <v-row align="center">
          <v-col align-self="center" class="d-flex justify-center">
            <v-card elevation="10" class="mx-auto">
              <v-img height="300" :src="immbg" class="white--text align-end">
                <v-card-title>
                  <v-icon color="white">mdi-police-badge</v-icon>เข้าสู่ระบบ
                  <v-spacer></v-spacer>
                  <span class="caption"> TPCC (version: {{ version }})</span>
                </v-card-title>
              </v-img>
              <v-container>
                <v-alert type="error" v-if="errorMessage != ''">
                  {{ errorMessage }}
                </v-alert>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field v-model="username" :rules="usernameRules" maxlength="13" hint="enter username"
                    label="Username" required>
                  </v-text-field>

                  <v-text-field v-model="password" type="password" :rules="passwordRules" hint="enter your password"
                    label="Password" required @keypress.enter="login"></v-text-field>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn color="indigo darken-4" text dark @click="readManualFile" x-large>
                  <v-icon>
                    mdi-file
                  </v-icon>
                  อ่านคู่มือการลงทะเบียนเข้าใช้งานระบบ TPCC
                </v-btn>
              </v-card-actions>
              <v-card-actions>

                <v-btn color="indigo darken-4" text dark to="/register" x-large class="mx-1">
                  <v-icon>
                    mdi-account-plus
                  </v-icon>
                  ลงทะเบียน
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="indigo darken-4" dark @click="login" :loading="loading" x-large>
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import immbg from '@/assets/card_img1.png'
export default {
  data() {
    return {
      valid: false,
      immbg,
      username: '',
      usernameRules: [v => !!v || 'กรอก Username'],
      password: '',
      passwordRules: [v => !!v || 'กรอก Password'],
      loading: false,
      errorMessage: '',
      version: '',
    }
  },
  methods: {
    readManualFile() {
      window.open('https://tpcc.police.go.th/v2/TPCC-register.pdf', '_blank') //to open in new tab
    },
    login() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      const user = {
        username: this.username,
        password: this.password,
      }
      this.$store
        .dispatch('Auth/login', user)
        .then(res => {
          console.log(res)
          if (res.statusCode === 112) {
            this.$router.push({ name: 'Dashboard' })
            return
          }
          this.$router.push({ name: 'OTP' })
        })
        .catch(err => {
          // console.log(err)
          this.errorMessage = err.error == 'invalid_grant' ? 'ไม่พบผู้ใช้งานนี้ในระบบ' : err.error
          if (err.response) {
            this.errorMessage = err.response.data.error
          }

          this.$refs.form.reset()
        })
        .finally(() => (this.loading = false))
    },
  },
  computed: {},
  created() {
    this.version = process.env.VUE_APP_VERSION

    console.log(this.$route.query.redirect)

    if (this.$route.query.redirect === 'satisfaction') {
      this.$router.push({ name: 'Satisfaction' })
    }

  },
}
</script>

<style scope>
.login {
  background-image: url('../../assets/login-bg1.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
